import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder } from '../../GlobalComponents/Builders';
import { Col, Container, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const RequestList = () => {
    
    document.title = 'View Requests'

    const [isProcessing, setIsProcessing] = useState(true);
    const [BasicTab, setBasicTab] = useState('1');

    const [pendingData, setPendingData] = useState([]);
    const [approvedData, setApprovedData] = useState([]);
    const [deniedData, setDeniedData] = useState([]);

    const statusTableBuilder = (data, title) => {
        
        return <TableBuilder
            subHeader={true}
            columnsToSearch={['status']}
            data={data}
            processing={isProcessing}
            title={title}
            columns = {[
                {
                    name: 'Name',
                    selector: (row) => row.name,
                    sortable: true,
                    center: true,
                },
                {
                    name: 'Status',
                    selector: (row) => row.status,
                    sortable: true,
                    center: true,
                },
                {
                    name: 'Endpoint',
                    selector: (row) => row.resource_path,
                    sortable: true,
                    center: true,
                },
                {
                    name: 'User Id',
                    selector: (row) => row.requested_by,
                    sortable: true,
                    center: true,
                },
                {
                    name: 'Date of Request',
                    selector: (row) => moment.utc(row.requested_at * 1000).format('l'),
                    sortable: true,
                    center: true,
                },
                {
                    name: 'Manage',
                    selector: (row) => <Link to={`${process.env.PUBLIC_URL}/requests/` + row.id}>View / Edit</Link>,
                    sortable: true,
                    center: true,
                },
            ]}
        />
    }

    useEffect(() => {

        ApiRequestBuilder('requestsStatusStatusGet', {'status':'pending'}, null, { setIsProcessing }).then(function(results) {
            setPendingData(results.data && results.data.length > 0 ? results.data : [])
        })

        ApiRequestBuilder('requestsStatusStatusGet', {'status':'approved'}, null, { setIsProcessing }).then(function(results) {
            setApprovedData(results.data && results.data.length > 0 ? results.data : [])
        })

        ApiRequestBuilder('requestsStatusStatusGet', {'status':'denied'}, null, { setIsProcessing }).then(function(results) {
            setDeniedData(results.data && results.data.length > 0 ? results.data : [])
        })

    }, []);

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink href="#" className={BasicTab === '1' ? 'active' : ''} onClick={() => setBasicTab('1')}>Pending</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={BasicTab === '2' ? 'active' : ''} onClick={() => setBasicTab('2')}>Approved</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={BasicTab === '3' ? 'active' : ''} onClick={() => setBasicTab('3')}>Denied</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={BasicTab}>
                                <TabPane className="fade show" tabId="1">
                                    <Col xl="12">
                                        {statusTableBuilder(pendingData, 'List Pending Requests')}
                                    </Col>
                                </TabPane>
                            </TabContent>
                            <TabContent activeTab={BasicTab}>
                                <TabPane className="fade show" tabId="2">
                                    <Col xl="12">
                                        {statusTableBuilder(approvedData, 'List Approved Requests')}
                                    </Col>
                                </TabPane>
                            </TabContent>
                            <TabContent activeTab={BasicTab}>
                                <TabPane className="fade show" tabId="3">
                                    <Col xl="12">
                                        {statusTableBuilder(deniedData, 'List Denied Requests')}
                                    </Col>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    );
};

export default RequestList;