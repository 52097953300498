import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Btn, H3, H4, H5, P, Image } from "../../../AbstractElements";
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { ApiRequestBuilder, ButtonBuilder, ToastBuilder } from '../../../GlobalComponents/Builders';
import moment from 'moment';

const Request = () => {

    let { requestId } = useParams();

    const [requestData, setRequestData] = useState({});

    const submitRequest = (status) => {
    
        ApiRequestBuilder('requestsRequestIdFulfillmentPost', { requestId }, status).then(function(results) {
            ToastBuilder('success', 'Request Approved')
            getRequestData()
        })
        
    }

    const getRequestData = () => {

        ApiRequestBuilder('requestsRequestIdGet', { requestId }).then(function(results) {
            
            if(results.data)
            {
                var data = results.data
                setRequestData(data)
            }
        })
    }

    useEffect(() => {

        document.title = 'View Request'
        
        getRequestData()
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                    <div className="edit-profile">
                        <Col sm="12" xl="12" className='xl-100' >
                            <Card>
                                <CardHeader className="pb-0">
                                    <H4 attrH4={{ className: "card-title mb-0" }}>{requestData.name} {'Request'}:</H4>          
                                </CardHeader>
                                <CardBody>
                                    <H5>Status: {requestData.status}</H5>
                                    <H5>Method: {requestData.method}</H5>
                                    <H5>Resource Path: {requestData.resource_path}</H5>
                                    <H5>Requested At: { moment.utc(requestData.requested_at * 1000).format('l')}</H5>
                                    <H5>Requested By: {requestData.requested_by}</H5>
                                    <H5>Payload: {requestData.request_payload}</H5>
                                </CardBody>
                                <CardFooter>
                                    <ButtonBuilder className = "m-r-15" onClick={() => submitRequest('Approved')}  label='Approve' type='submit'  />
                                    <ButtonBuilder onClick={() => submitRequest('Denied')}  label='Deny' color='danger' type='submit'  />
                                </CardFooter>
                            </Card>
                        </Col>
                    </div>
            </Container>
        </Fragment>
    );
};
export default Request;