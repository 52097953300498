
import React, { Fragment, useContext} from 'react';
import { Maximize } from 'react-feather';
import { LI, UL } from '../../../AbstractElements';
import CustomizerContext from '../../../_helper/Customizer';
import UserOptions from './UserOptions';
import MoonLight from './MoonLight';
import { ButtonBuilder } from '../../../GlobalComponents/Builders';
import { AccountContext } from '../../../GlobalComponents/Authentication/Accounts';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const Rightbar = () => {

    const { getSession, userSession } = useContext(AccountContext);

    const { sidebarResponsive } = useContext(CustomizerContext);
    //full screen function
    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    return (
        <Fragment>
            <div className="nav-right col pull-right right-menu p-0">
                <UL attrUL={{ className: `simple-list d-flex flex-row nav-menus ${sidebarResponsive ? 'open' : ''}` }} >
                    <LI>
                        {userSession['custom:emulationActive'] === 'true' ? 
                        <ButtonBuilder label={'Exit ' + (userSession['custom:clientId'] === '0' ? 'Partner' : 'Client') + ' Portal'} onClick={() => {

                            getSession().then(({ user }) => {
                                var attributes = [];

                                if (userSession.access_level == 'partner')
                                {
                                    attributes = [
                                        new CognitoUserAttribute({ Name: "custom:emulationActive", Value: 'false' }),
                                        new CognitoUserAttribute({ Name: "custom:partnerId", Value: '0' }),
                                    ]
                                } 
                                else if (userSession.access_level == 'client')
                                {
                                    attributes = [
                                        new CognitoUserAttribute({ Name: "custom:clientId", Value: '0' })
                                    ]

                                    if (Number(userSession['custom:partnerId']) > 0)
                                    {
                                        attributes.push(new CognitoUserAttribute({ Name: "custom:emulationActive", Value: 'false' }))
                                    }
                                }
                          
                                user.updateAttributes(attributes, (err, result) => {
                                  if (err) console.error(err);
                                  window.location.href = process.env.PUBLIC_URL
                                });
                              });
                        }}/> : null} 
                    </LI>
                    <LI><a className="text-dark" href="#javascript" onClick={goFull}>
                        <Maximize />
                    </a></LI>
                    {/*<LanguageClass />*/}
                    {/*<Bookmarks />*/}
                    {/*<Notifications />*/}
                    <MoonLight />
                    {/*<MessageDrop />*/}
                    <UserOptions />
                </UL>
            </div>
        </Fragment >
    );
};

export default Rightbar;