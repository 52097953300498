import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Btn, H3, H4, P, Image } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder } from '../../../GlobalComponents/Builders';
import { setInitialFormValues } from '../../../GlobalComponents/Helpers';

document.title = 'View User'

const User = () => {

    let { userId } = useParams();

    const [data, setData] = useState([]);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const onProfileEdit = (profileInformation) => {
        ApiRequestBuilder('usersUserIdPatch', { userId }, { ...profileInformation }).then(function(results) {
            ToastBuilder('success', 'User Updated Successfully')
            setData(getUserData())
        })
    }

    const getUserData = () => {

        ApiRequestBuilder('usersUserIdGet', { userId }).then(function(results) {
            setInitialFormValues(results.data[0], setValue)
            setData(results.data[0])
        })
    }

    useEffect(() => {
        getUserData()
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                {data ?
                    <div className="edit-profile">
                        <Row>
                            <Col xl="6">
                                <Form onSubmit={handleSubmit(onProfileEdit)}>
                                    <Card>
                                        <CardBody>
                                                <Row className="mb-2">
                                                    <div className="profile-title">
                                                        <div className="media">
                                                            <Image attrImage={{ className: 'img-70 rounded-circle', alt: '', src: `${require('../../../assets/images/user/7.jpg')}` }} />
                                                            <div className="media-body">
                                                                <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>{data.first_name + ' ' + data.last_name}</H3>
                                                                <P>USER</P>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                                    <RegisteredInputsBuilder 
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={[
                                                            {
                                                                label: 'First Name',
                                                                placeholder: 'First Name',
                                                                size: 12,
                                                                name: 'firstName'
                                                            },
                                                            {
                                                                label: 'Last Name',
                                                                placeholder: 'Last Name',
                                                                size: 12,
                                                                name: 'lastName'
                                                            },
                                                            {
                                                                label: 'Email Address',
                                                                placeholder: 'client@email.com',
                                                                size: 12,
                                                                name: 'emailAddress'
                                                            },
                                                            {
                                                                label: 'Phone Number',
                                                                placeholder: '1234567890',
                                                                size: 12,
                                                                name: 'phoneNumber'
                                                            }
                                                        ]}
                                                    />
                                        </CardBody>
                                        <CardFooter className="text-end">
                                            <Btn attrBtn={{ color: "primary", type: "submit" }} >{'Update Profile'}</Btn>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                            {/*<Col xl="6">
                                <Form onSubmit={handleSubmit(onProfileEdit)}>
                                    <Card>
                                        <CardHeader className='pb-0'>
                                            <H4 attrH4={{ className: 'card-title mb-0' }}>Edit Profile</H4>
                                        </CardHeader>
                                        <CardBody>
                                                    <RegisteredInputsBuilder 
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={[
                                                            {
                                                                label: 'Country',
                                                                placeholder: 'disabled',
                                                                size: 12,
                                                                type: 'static',
                                                                name: 'country'
                                                            },
                                                            {
                                                                label: 'City',
                                                                placeholder: 'disabled',
                                                                size: 12,
                                                                type: 'static',
                                                                name: 'city'
                                                            },
                                                            {
                                                                label: 'State',
                                                                placeholder: 'disabled',
                                                                size: 12,
                                                                type: 'static',
                                                                name: 'state'
                                                            },
                                                            {
                                                                label: 'ZIP Code',
                                                                placeholder: 'disabled',
                                                                size: 12,
                                                                name: 'zipCode',
                                                                type: 'static'
                                                            }
                                                        ]}
                                                    />
                                        </CardBody>
                                        <CardFooter className="text-end">
                                            <Btn attrBtn={{ color: "primary", type: "submit" }} >{'Update Profile'}</Btn>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>*/}
                        </Row>
                    </div> : null }
            </Container>
        </Fragment>
    );
};
export default User;