import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import { AccountContext } from '../GlobalComponents/Authentication/Accounts';

const LayoutRoutes = () => {

  const { userSession } = useContext(AccountContext);

  return (
    <>
      <Routes>
        {routes.map(({ path, Component, access }, i) => {
          return access[userSession.access_level + '_access'] ?
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          : null
      })}
      </Routes>
    </>
  );
};

export default LayoutRoutes;