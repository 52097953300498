import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Btn, H3, H4, H5, P, Image } from "../../../AbstractElements";
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ModalButtonBuilder, FormOptionsBuilder, ButtonBuilder } from '../../../GlobalComponents/Builders';
import { buildOptionsFromArray, setInitialFormValues } from '../../../GlobalComponents/Helpers';
import ClientAccount from './components/accounting';

const Client = () => {

    let { clientId } = useParams();

    const [clientData, setClientData] = useState({});
    const [clientName, setClientName] = useState('');

    const [BasicTab, setBasicTab] = useState('1');

    const installationSteps = [
        'Go to https://control.kraftkennedy.com',
        'Click Support',
        'Click Create',
        'Name the session "WD - ' + (clientName ? clientName.split(' ')[0] : '') + '"',
        'Direct client to https://control.kraftkennedy.com ',
        'Give client the 6 digit code',
        'Click Join',
        'Once session is established, on their machine go to https://worldox-web.s3.amazonaws.com/installs/Worldox+Web+3.2+Proxy.exe',
        'Go to Downloads and Run proxy installer as Administrator',
        'Click Next',
        'Click I agree',
        'Click Next',
        'Open WDADMIN',
        'Click About',
        'Click Help',
        'Click ctrl+D',
        'Double click row starting with Worldox_INIs',
        'Copy path from address bar in file explorer',
        'Go back to Installer Wizard and add path to Installer',
        'Click Next',
        //'While the install is finishing...',
        //'Go to the Shared folder',
        //'Click Modules',
        //'Click Web3x',
        //'Copy three pdfs to desktop',
        'Follow the wizard, enter the HWW license: ' + clientData.hww_license,
        'Open WDADMIN',
        'Click Users',
        'Find all users listed in the site survey',
        'For each user, double click their record',
        'Add the user\'s email address',
        'Check the box for "Enable Browser/Mobile Login"',
        'Click Save',
        'Close Users Window',
        'Click "Group Security"',
        'Click Features Radio Button',
        'Discuss 2FA settings',
        'Discuss Web Agent and Settings',
        'Click Save',
        'Close the Worldox Web Launcher',
        'Click Worldox Web Service Installer Shortcut',
        'Click Install Service',
        'Set Dropdown to Autostart Delayed',
        'Find service account running the Indexer',
        'Have client enter the password twice',
        'Click OK',
        'Click Start Service',
        'Open Web Launcher',
        'Click Edit (?)',
        'Click Download',
        'Enter cdn.worldoxweb.com/proxies/wear-files',
        'Click Ok',
        'Restart Service in Task Manager',
        'Confirm that list of users is on the Web Servers',
        'Open Web browser at worldoxweb.com',
        'Have client log in',
        'Discuss Integration Agent',
        'Discuss PDFs',
        'Remind Client to reach out to Worldox Support with any issues'
    ]

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const onProfileEdit = (profileInformation) => {

        ApiRequestBuilder('clientsClientIdPatch', { clientId }, { ...profileInformation }).then(function(results) {
            ToastBuilder('success', 'Client Updated Successfully')
            getClientData()
        })
    }

    const getClientData = () => {

        ApiRequestBuilder('clientsClientIdGet', { clientId }).then(function(results) {
            
            if(results.data)
            {
                var data = results.data

                data.installation_date = data.installation_date && data.installation_date !== 'Invalid date' ? moment(data.installation_date * 1000).format('l') : ''
                data.cancellation_date = data.cancellation_date && data.cancellation_date !== 'Invalid date' ? moment(data.cancellation_date * 1000).format('l') : ''
                
                data.defaultContactEmail = data.info.defaultContact.email ? data.info.defaultContact.email : ''
                data.defaultContactName = data.info.defaultContact.name ? data.info.defaultContact.name : ''
                
                setInitialFormValues(data, setValue)
                setInitialFormValues(data.info, setValue)
                setClientData(data)
                setClientName(data.name)
            }
        })
    }

    useEffect(() => {

        document.title = 'View Client'
        
        getClientData()
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                    <div className="edit-profile">
                        <Col sm="12" xl="12" className='xl-100' >
                            <Card>
                                <CardHeader className="pb-0">
                                    <H4 attrH4={{ className: "card-title mb-0" }}>{'Client: ' + clientName}</H4>             
                                </CardHeader>
                                <CardBody>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink href="#" className={BasicTab === '1' ? 'active' : ''} onClick={() => setBasicTab('1')}>Worldox</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className={BasicTab === '2' ? 'active' : ''} onClick={() => setBasicTab('2')}>Company</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className={BasicTab === '3' ? 'active' : ''} onClick={() => setBasicTab('3')}>Accounting</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className={BasicTab === '4' ? 'active' : ''} onClick={() => setBasicTab('4')}>Installation</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={BasicTab}>
                                        <TabPane className="fade show" tabId="1">
                                            <Col xl="12">
                                                <Form onSubmit={handleSubmit(onProfileEdit)}>
                                                        <CardBody>
                                                                <RegisteredInputsBuilder 
                                                                    registrator={register}
                                                                    registratorErrors={errors}
                                                                    inputs={[
                                                                        {
                                                                            label: 'Worldox Client ID',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            type: 'number',
                                                                            name: 'worldoxClientId'
                                                                        },
                                                                        {
                                                                            label: 'License Count',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            type: 'static',
                                                                            name: 'licenseCount'
                                                                        },
                                                                        {
                                                                            label: 'Installation Date',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'installationDate',
                                                                            type: 'date'
                                                                        },
                                                                        {
                                                                            label: 'Cancellation Date',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'cancellationDate',
                                                                            type: 'static'
                                                                        },
                                                                        {
                                                                            label: 'GX4 License',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'gx4License'
                                                                        },
                                                                        {
                                                                            label: 'Maintenance License',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'maintenanceLicense'
                                                                        },
                                                                        {
                                                                            label: 'HWW License',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'hwwLicense'
                                                                        },
                                                                        {
                                                                            label: 'Is in WEAR?',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            type: 'select',
                                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(['No, Client Is Not In Latest', 'Yes, Client Has Been Added'])}/>,
                                                                            name: 'addedToWear'
                                                                        }
                                                                    ]}
                                                                />
                                                                <CardFooter className="text-end">
                                                                    <ButtonBuilder label='Update Client' type='submit'/>
                                                                </CardFooter>
                                                        </CardBody>
                                                </Form>
                                            </Col>
                                        </TabPane>
                                        <TabPane className="fade show" tabId="2">
                                            <Col xl="12">
                                                <Form onSubmit={handleSubmit(onProfileEdit)}>
                                                        <CardBody>
                                                                <RegisteredInputsBuilder 
                                                                    registrator={register}
                                                                    registratorErrors={errors}
                                                                    inputs={[
                                                                        {
                                                                            label: 'Name',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'name'
                                                                        },
                                                                        {
                                                                            label: 'Website',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'website'
                                                                        },
                                                                        {
                                                                            label: 'Phone Number',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'phoneNumber'
                                                                        },
                                                                        {
                                                                            label: 'Address 1',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'addressLine1'
                                                                        },
                                                                        {
                                                                            label: 'Address 2',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'addressLine2'
                                                                        },
                                                                        {
                                                                            label: 'City',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'city'
                                                                        },
                                                                        {
                                                                            label: 'State',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'state'
                                                                        },
                                                                        {
                                                                            label: 'Zip ',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'zip'
                                                                        },
                                                                        {
                                                                            label: 'Contact Name',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'defaultContactName'
                                                                        },
                                                                        {
                                                                            label: 'Contact Email Address',
                                                                            size: 6,
                                                                            labelSize: 4,
                                                                            name: 'defaultContactEmail'
                                                                        }
                                                                    ]}
                                                                />
                                                                <CardFooter className="text-end">
                                                                    <ButtonBuilder label='Update Client' type='submit'/>
                                                                </CardFooter>
                                                        </CardBody>
                                                </Form>
                                            </Col>
                                        </TabPane>
                                        <TabPane className="fade show" tabId="3">
                                            <Col xl="12">
                                                <ClientAccount clientData={clientData}/>
                                            </Col>
                                        </TabPane>
                                        <TabPane className="fade show" tabId="4">
                                            <Col xl="12">
                                                <Form onSubmit={handleSubmit()}>
                                                        <CardBody>
                                                            <h4>General Installation Steps</h4>
                                                            {installationSteps.map((step, i) => <li key={i}>{step}</li>)}
                                                        </CardBody>
                                                </Form>
                                            </Col>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </div>
            </Container>
        </Fragment>
    );
};
export default Client;