import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, ButtonBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody } from 'reactstrap'
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const PartnerList = () => {

    const { getSession, userSession } = useContext(AccountContext);

    const [partnerData, setPartnerData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
    
        document.title = 'View Partners'

        ApiRequestBuilder('partnersGet', null, null, { setIsProcessing })
            .then(function(results) {
                setPartnerData(results.data && results.data.length > 0 ? results.data : [])
            }
        )
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['name']}
                                data={partnerData}
                                processing={isProcessing}
                                title='List Partners'
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.partner_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Manage',
                                        selector: (row) => 
                                            <ButtonBuilder label='Enter Partner Portal' onClick={() =>
                                                getSession().then(({ user }) => {
                                                    const attributes = [
                                                        new CognitoUserAttribute({ Name: "custom:partnerId", Value: String(row.partner_id) }),
                                                        new CognitoUserAttribute({ Name: "custom:emulationActive", Value: 'true' }),
                                                    ];
                                            
                                                    user.updateAttributes(attributes, (err, result) => {
                                                        if (err) console.error(err);
                                                        window.location.href = process.env.PUBLIC_URL
                                                    });
                                                })} 
                                            />
                                        ,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default PartnerList;