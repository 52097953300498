import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, ToastBuilder, RegisteredInputsBuilder } from '../../GlobalComponents/Builders';
import { buildOptionsFromArray, getStaticArray } from '../../GlobalComponents/Helpers';
import { ModalButtonBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, Form } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ClientList = () => {

    const [clientData, setClientData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleClientCreate = (newClientData) => {
        
        ApiRequestBuilder('clientsPost', null, newClientData).then(function(results){
            ToastBuilder('success', 'Client Creation Successful')
            window.location.href = process.env.PUBLIC_URL + '/clients/' + String(results.data)
        }).catch((err) => {
            console.log(err);
            ToastBuilder('error', 'Unable to Create Client')
        })
    }

    useEffect(() => {
    
        document.title = 'View Clients'

        ApiRequestBuilder('clientsGet', null, null, { setIsProcessing })
            .then(function(results) {
                setClientData(results.data && results.data.length > 0 ? results.data : [])
            }
        )
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['name']}
                                data={clientData}
                                processing={isProcessing}
                                actions={<ModalButtonBuilder title='Create Client' className='btn-secondary'
                                    body={
                                        <Form id='create-client-form' onSubmit={handleSubmit(handleClientCreate )}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={[
                                                    {
                                                        label: 'Exsiting Client ID',
                                                        size: 12,
                                                        type: 'number',
                                                        name: 'existingClientId'
                                                    },
                                                    {
                                                        name: '-',
                                                        size: 12,
                                                        type: 'separator',
                                                    },
                                                    {
                                                        label: 'Client Name',
                                                        size: 12,
                                                        required: false,
                                                        name: 'name'
                                                    },
                                                    {
                                                        label: 'Website',
                                                        size: 12,
                                                        required: false,
                                                        name: 'website'
                                                    },
                                                    {
                                                        label: 'Phone Number',
                                                        size: 12,
                                                        required: false,
                                                        name: 'phoneNumber'
                                                    },
                                                    {
                                                        label: 'Address 1',
                                                        size: 12,
                                                        required: false,
                                                        name: 'addressLine1'
                                                    },
                                                    {
                                                        label: 'Address 2',
                                                        size: 12,
                                                        name: 'addressLine2'
                                                    },
                                                    {
                                                        label: 'City',
                                                        size: 12,
                                                        required: false,
                                                        name: 'city'
                                                    },
                                                    {
                                                        label: 'State',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(getStaticArray('usStates'), {label : 'name', value: 'isoCode'})}/>,
                                                        name: 'state'
                                                    },
                                                    {
                                                        label: 'Zip ',
                                                        size: 12,
                                                        required: false,
                                                        name: 'zip'
                                                    },
                                                    {
                                                        label: 'Market',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(getStaticArray('markets'), {label : 'name', value: 'id'})}/>,
                                                        name: 'market'
                                                    },
                                                    {
                                                        label: 'Contact First Name',
                                                        size: 12,
                                                        required: false,
                                                        name: 'defaultContactFirstName'
                                                    },
                                                    {
                                                        label: 'Contact Last Name',
                                                        size: 12,
                                                        required: false,
                                                        name: 'defaultContactLastName'
                                                    },
                                                    {
                                                        label: 'Contact Email Address',
                                                        size: 12,
                                                        required: false,
                                                        name: 'defaultContactEmail'
                                                    },
                                                    {
                                                        label: 'IT Email Address',
                                                        size: 12,
                                                        name: 'ITEmail'
                                                    },
                                                    {
                                                        label: 'IT First Name',
                                                        size: 12,
                                                        name: 'ITFirstName'
                                                    },
                                                    {
                                                        name: '-',
                                                        size: 12,
                                                        type: 'separator',
                                                    },
                                                    {
                                                        label: 'Worldox Client ID',
                                                        size: 12,
                                                        type: 'number',
                                                        name: 'worldoxClientId'
                                                    },
                                                    {
                                                        label: 'License Count',
                                                        size: 12,
                                                        required: false,
                                                        name: 'licenseCount'
                                                    },
                                                    {
                                                        label: 'GX4 License',
                                                        size: 12,
                                                        required: false,
                                                        name: 'gx4License'
                                                    },
                                                    {
                                                        label: 'Maintenance License',
                                                        size: 12,
                                                        required: false,
                                                        name: 'maintenanceLicense'
                                                    },
                                                    {
                                                        label: 'HWW License',
                                                        size: 12,
                                                        required: false,
                                                        name: 'hwwLicense'
                                                    },
                                                    {
                                                        label: 'Is in WEAR?',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['No, Client Is Not In Latest', 'Yes, Client Has Been Added'])}/>,
                                                        name: 'addedToWear'
                                                    },
                                                    {
                                                        label: 'Waive Install Fee',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['No, Client Will be Charged a Fee', 'Yes, Client Will NOT be Charged a Fee'])}/>,
                                                        name: 'waiveInstallFee'
                                                    },
                                                    {
                                                        label: 'Send Installation Email',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['No, I Will Speak to Client', 'Yes, Send Email to Client'])}/>,
                                                        name: 'sendInstallEmail'
                                                    }
                                                ]}/>
                                        </Form>
                                    }
                                />}
                                title='List Clients'
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.worldox_client_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'License Count',
                                        selector: (row) => row.license_count,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Installation Date',
                                        selector: (row) => row.installation_date ? moment.utc(row.installation_date * 1000).format('l') : '-',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Cancellation Date',
                                        selector: (row) => row.cancellation_date ? moment.utc(row.cancellation_date * 1000).format('l') : '-',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Active',
                                        selector: (row) => row.cancellation_date ? '-' : row.is_active,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Manage',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/clients/` + row.client_id}>View / Edit</Link>,
                                        sortable: true,
                                        center: true,
                                    },
                                ]}
                            />
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default ClientList;