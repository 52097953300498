import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder } from '../../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom';

const UserList = () => {

    const [userData, setData] = useState([]);

    useEffect(() => {
    
        document.title = 'View Users'

        ApiRequestBuilder('usersGet')
            .then(function(results) {
                setData(results.data && results.data.length > 0 ? results.data : [])
            })
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <TableBuilder
                                data={userData}
                                title='List Users'
                                processing={false}
                                columns = {[
                                    {
                                        name: 'Name',
                                        selector: (row) => row.first_name + ' ' + row.last_name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Email Address',
                                        selector: (row) => row.email_address,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Manage',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/users/` + row.user_id}>View / Edit</Link>,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default UserList;