import React, { Fragment, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { H4 } from "../../AbstractElements";
import { Card, CardHeader, CardBody } from 'reactstrap'
import { ApiRequestBuilder, ButtonBuilder} from '../../GlobalComponents/Builders';

const Infrastructure = () => {

    const [serverData, setServerData] = useState([]);
    const [spinner, setSpinner] = useState(true);
    
    const getServerData = () => {

        ApiRequestBuilder('infrastructureServersGet').then(function(results) {
            
            if(results.data)
            {
                setServerData(results.data)
                setSpinner(false)
            }
        })
    }

    const refreshPage = () => {

        getServerData()
        setSpinner(true)
    }
    
    useEffect(() => {

        getServerData()
        document.title = 'View Infrastructure'
    }, []);


    return (
        <Fragment>
                <div className ="d-flex justify-content-center">
                    {spinner ? <Spinner animation="border" size="xl" role="status"></Spinner>  : ''}
                </div>
                
                <div className ="d-flex justify-content-end">
                    <ButtonBuilder  className='mb-2' onClick={refreshPage}  label='Refresh Page' type='submit' useSpinner={false} />
                </div>

                {serverData.map((server) => (
                    <Card key={server.name}>
                        <CardHeader className="pb-0">
                            <H4 attrH4={{ className: "card-title mb-0" }}><a href='https://control.kraftkennedy.com'>
                                {server.name + ' - ' + server.ip}
                            </a></H4>             
                        </CardHeader>
                        <CardBody>
                        <img
                            src={'data:image/png;base64, ' + server.image}
                            style={{ width: 900, height: 600 }}
                        />
                        </CardBody>
                    </Card>
                ))}
        </Fragment>
    );
};
export default Infrastructure;