import { ApiRequestBuilder, ToastBuilder } from '../Builders/index'

function buildRandomId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
   return result;
}

function performStringSearch(str, code = null, pattern = null) {
    
    switch(code)
    {
        case 'special':
            pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            break;
        case 'uppercase':
            pattern = /[A-Z]/;
            break;
        case 'lowercase':
            pattern = /[a-z]/;
            break;
        case 'number':
            pattern = /[0-9]/;
            break;
        default:
            pattern = /[a-zA-Z]/;
            break;
    }

    return pattern.test(str);
}

// https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function rekeyArray(array, target) {
            
    let rekeyed_array = {};
    
    target = target.includes('/') ? target.split('/') : target;

    array.map((element) => {

        if (typeof target === 'string') {

            rekeyed_array[String(element[target])] = element;

        } else {

            rekeyed_array[String(element[target[0]][target[1]])] = element;

        }
    })

    return rekeyed_array;
}

function createValuesArray(array, key)
{
    var newArray = [];
    
    array.forEach(function(arr)
    {
        newArray.push(arr[key]);
    });

    return newArray;
}

const uploadPresignedFile = (fileObject, sdkPath, params = {}, body = {}, callback = () => {}, options = {}) => {

    return ApiRequestBuilder(sdkPath, params, body)
    .then(function(results) {

        const formData = new FormData();

        Object.keys(results.data.fields).forEach((field) => {
            formData.append(field, results.data.fields[field]);
        })
        
        /**
         * The file has to be added last...
         * https://medium.com/@spenceraford/using-python-lambdas-javascript-to-get-photos-to-s3-83ddde1ef5b
         */
        formData.append("file", fileObject[0]);
        
        fetch(results.data.url, { method: 'POST', body: formData })
            .then(e => {
                if (e.status === 200 || e.status === 204)
                {
                    ToastBuilder('success', 'File Uploaded Successfully')
                    // Apparently this refresh of the data is faster than the db so we need a delay
                    setTimeout(() => { callback() }, options.timeout ? options.timeout : '3000')
                } else ToastBuilder('error', 'Something Went Wrong, Please Try Again')
            })
            .catch(err => console.log('err', err))
    })
}

/**
 * Due to the way react works or at least how we understand it at this point. If we
 * fetch data via an API call and then try to populate form fields with the information
 * we receive using registrationSettings, it doesn't work. If it is hard coded it works 
 * so we assume it is a timing thing. This function is designed to set all the initial
 * form values based on the data retrieved. We loop through all the column names while
 * blindy setting form fields whose name matches that of the camelCase form of the column
 * name.
 */
const setInitialFormValues = (data, setValue) => {
        
    Object.keys(data).map((column) => {

        let split_column = column.split('_')
        
        split_column.shift()

        let camelCaseColumn = split_column.map((column_part) => {
            return capitalizeFirstLetter(column_part)
        })

        let column_value = data[column] ? data[column] : ''

        setValue(column.split('_')[0] + camelCaseColumn.join(''), column_value)
    })

}

const convertTimestampToTimePicker = (timestamp) => {
    var curr = new Date(timestamp * 1000);
    curr.setDate(curr.getDate());
    return curr.toISOString().substr(0,10);
}

const resetAllFormFields = (formData, resetFieldFunction) => {
    Object.keys(formData).forEach(field => resetFieldFunction(field))
}

const buildStaffOptions = (setData) => {

    ApiRequestBuilder('staffGet').then(function(results) {
        setData(results.data.map((items, i) =>
            <option value={items.user_id} key={items.user_id}>{items.first_name + ' ' + items.last_name}</option>
        ))
    })
}

const buildAddressOptions = () => {
    return [];
    /*Country.getAllCountries().map((items, i) =>
        <option key={items.isoCode} value={items.isoCode}>{capitalizeFirstLetter(items.name)}</option>
    )*/
}

const buildOptionsFromArray = (optionArray, optionSettings = {} ) => { 
    return optionArray.map((option, i) =>
    {
        let label = optionSettings.label ? option[optionSettings.label] : option
        let value = optionSettings.value ? option[optionSettings.value] : optionSettings.labelIsValue ? option : i

        return {
            label: label,
            value: value
        }
    })
}

const getStaticArray = (arrayName) => {
    
    switch(arrayName)
    {
        case 'numbers':
            var countArray = [];
            for (let step = 1; step < 100; step++) {
                countArray.push({
                  num: step,
                });
            }

            return countArray;
        
        case 'markets':
          return [
            { id: 1, name: 'Financial Services' },
            { id: 48, name: 'Law firm' },
            { id: 32, name: 'Healthcare, Pharma & Biotech' },
            { id: 50, name: 'Government' },
          ];
        
        case 'usStates':
            return [
                { isoCode: 'AL', name: 'Alabama' },
                { isoCode: 'AK', name: 'Alaska' },
                { isoCode: 'AZ', name: 'Arizona' },
                { isoCode: 'AR', name: 'Arkansas' },
                { isoCode: 'CA', name: 'California' },
                { isoCode: 'CO', name: 'Colorado' },
                { isoCode: 'CT', name: 'Connecticut' },
                { isoCode: 'DE', name: 'Delaware' },
                { isoCode: 'DC', name: 'District Of Columbia' },
                { isoCode: 'FL', name: 'Florida' },
                { isoCode: 'GA', name: 'Georgia' },
                { isoCode: 'HI', name: 'Hawaii' },
                { isoCode: 'ID', name: 'Idaho' },
                { isoCode: 'IL', name: 'Illinois' },
                { isoCode: 'IN', name: 'Indiana' },
                { isoCode: 'IA', name: 'Iowa' },
                { isoCode: 'KS', name: 'Kansas' },
                { isoCode: 'KY', name: 'Kentucky' },
                { isoCode: 'LA', name: 'Louisiana' },
                { isoCode: 'ME', name: 'Maine' },
                { isoCode: 'MD', name: 'Maryland' },
                { isoCode: 'MA', name: 'Massachusetts' },
                { isoCode: 'MI', name: 'Michigan' },
                { isoCode: 'MN', name: 'Minnesota' },
                { isoCode: 'MS', name: 'Mississippi' },
                { isoCode: 'MO', name: 'Missouri' },
                { isoCode: 'MT', name: 'Montana' },
                { isoCode: 'NE', name: 'Nebraska' },
                { isoCode: 'NV', name: 'Nevada' },
                { isoCode: 'NH', name: 'New Hampshire' },
                { isoCode: 'NJ', name: 'New Jersey' },
                { isoCode: 'NM', name: 'New Mexico' },
                { isoCode: 'NY', name: 'New York' },
                { isoCode: 'NC', name: 'North Carolina' },
                { isoCode: 'ND', name: 'North Dakota' },
                { isoCode: 'OH', name: 'Ohio' },
                { isoCode: 'OK', name: 'Oklahoma' },
                { isoCode: 'OR', name: 'Oregon' },
                { isoCode: 'PA', name: 'Pennsylvania' },
                { isoCode: 'RI', name: 'Rhode Island' },
                { isoCode: 'SC', name: 'South Carolina' },
                { isoCode: 'SD', name: 'South Dakota' },
                { isoCode: 'TN', name: 'Tennessee' },
                { isoCode: 'TX', name: 'Texas' },
                { isoCode: 'UT', name: 'Utah' },
                { isoCode: 'VT', name: 'Vermont' },
                { isoCode: 'VA', name: 'Virginia' },
                { isoCode: 'WA', name: 'Washington' },
                { isoCode: 'WV', name: 'West Virginia' },
                { isoCode: 'WI', name: 'Wisconsin' },
                { isoCode: 'WY', name: 'Wyoming' }
            ];
         
        default: return []
    }
    
}

export default buildRandomId;
export { 
    performStringSearch, 
    capitalizeFirstLetter, 
    setInitialFormValues, 
    buildStaffOptions, 
    buildAddressOptions, 
    buildOptionsFromArray, 
    rekeyArray, 
    getStaticArray,
    createValuesArray,
    lowercaseFirstLetter, 
    uploadPresignedFile,
    resetAllFormFields,
    convertTimestampToTimePicker
}