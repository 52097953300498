import { Container, Row, Col, CardBody, Card } from 'reactstrap';
import React, { Fragment, useState, useEffect } from 'react';
import { H4 } from '../../../AbstractElements';
import { Database, ShoppingBag, MessageCircle, UserPlus } from 'react-feather';
import CountUp from 'react-countup';
import { ApiRequestBuilder } from '../../../GlobalComponents/Builders';

const Dashboard = () => {

    const [numberOfClients, setNumberOfClients] = useState(0);
    const [numberOfActiveClients, setNumberOfActiveClients] = useState(0);
    const [numberOfreceived, setNumberOfreceived] = useState(0);
    const [numberOfPendingRequested, setNumberOfPendingRequested] = useState(0);

    useEffect(() => {

        document.title = 'View Dashboard'

        ApiRequestBuilder('clientsGet').then(function(results) {
            setNumberOfClients(results.data.length)
            setNumberOfActiveClients(12)
            setNumberOfPendingRequested(17)
            setNumberOfreceived(24)
        })
    }, []);

    var tiles = [
        {
            id: 1,
            icon: <Database />,
            title: 'Clients',
            count: <CountUp end={numberOfClients} duration={3} />,
            iconWithClass: <Database className="icon-bg" />
        },
        {
            id: 2,
            icon: <ShoppingBag />,
            title: 'Active Clients',
            count: <CountUp end={numberOfActiveClients} duration={3} />,
            iconWithClass: <ShoppingBag className="icon-bg" />
        },
        {
            id: 3,
            icon: <MessageCircle />,
            title: 'Revenue',
            count: <CountUp end={numberOfreceived} duration={3} />,
            iconWithClass: <MessageCircle className="icon-bg" />
        },
        {
            id: 4,
            icon: <UserPlus />,
            title: 'One More',
            count: <CountUp end={numberOfPendingRequested} duration={3} />,
            iconWithClass: <UserPlus className="icon-bg" />
        },
    ]
    
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Fragment>
                        {tiles.map((item) =>
                            <Col sm="6" xl="3" lg="6" key={item.id}>
                                <Card className="o-hidden border-0">
                                    <CardBody className={item.classCompo}>
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center">
                                                {item.icon}
                                            </div>
                                            <div className="media-body">
                                                <span className="m-0">{item.title}</span>
                                                <H4 attrH4={{ className: 'mb-0 counter' }} >{item.count}</H4>
                                                {item.iconWithClass}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Fragment>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Dashboard;