import React from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import { Account } from './GlobalComponents/Authentication/Accounts';

const App = () => (
  <div className="App">
    <CustomizerProvider>
      <AnimationThemeProvider>
        <Account>
          <Routers />
        </Account>
      </AnimationThemeProvider>
    </CustomizerProvider>
  </div>
);

export default App;
