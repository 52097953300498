import { Container, Row, Col, Dropdown, Form, CardHeader, Card,  CardBody, CardFooter, Spinner } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TableBuilder, { ApiRequestBuilder, RegisteredInputsBuilder, ToastBuilder, ModalButtonBuilder } from '../../../GlobalComponents/Builders';
import { resetAllFormFields, uploadPresignedFile } from '../../../GlobalComponents/Helpers';

const FilesInstallers = () => {
    
    const { register, handleSubmit, resetField, formState: { errors } } = useForm();

    const [isUploading, setIsUploading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const [installerData, setInstallerData] = useState([]);

    const handleInstallerUpload = (file) => {

        if (file['installerToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else if (!file['installerToUpload'][0].name.includes('.exe'))
        {
            ToastBuilder('error', 'Please Select a Valid .exe File to Upload')
        }
        else
        {
            // If this is an installer, it must be an exe
            if (!file['installerToUploadName'].includes('.exe'))
            {
                file['installerToUploadName'] = file['installerToUploadName'] + '.exe';
            }
            
            setIsUploading(true)

            uploadPresignedFile(file['installerToUpload'], 'filesInstallersUploadPost', null, { 
                bucket: 'worldox-web',
                key : 'installs/' + file['installerToUploadName']
            }, () => {getInstallerFiles(); setIsUploading(false)}, { timeout: '5000' })
        }

        resetAllFormFields(file, resetField)
    }

    const getInstallerFiles = () => {
    
        ApiRequestBuilder('filesInstallersGet', null, null, { setIsProcessing })
            .then(function(results) {
                setInstallerData(results.data && results.data.length > 0 ? results.data : [])
            })
    }

    useEffect(() => {
        
        document.title = 'View Installers'
        getInstallerFiles()
    }, []);

    return (
    <Row>

        <Container>
            <Card>
            <CardBody>
                {isUploading ? 
                    <Fragment>
                        <center>
                            <h4>Uploading File...</h4>
                        </center>
                        <div className ="d-flex justify-content-center">
                            <Spinner animation="border" size="xl" role="status"/>
                        </div>
                    </Fragment>
                     : 
                <TableBuilder
                    data={installerData}
                    title='List Installers'
                    processing={isProcessing}
                    actions={
                        <ModalButtonBuilder title='Upload Installer' className='btn-secondary'
                            body={
                                <Form id='upload-installer-form' onSubmit={handleSubmit(handleInstallerUpload)}>
                                    <RegisteredInputsBuilder 
                                        registrator={register}
                                        registratorErrors={errors}
                                        inputs={[
                                            {
                                                label: 'Name',
                                                size: 12,
                                                name: 'installerToUploadName',
                                                placeholder: 'Worldox Web 3.2 Proxy.exe'
                                            },
                                            {
                                                label: 'File',
                                                size: 12,
                                                type: 'file',
                                                name: 'installerToUpload'
                                            },
                                        ]}
                                    /> 
                                </Form>
                            }
                        />
                    }
                    columns = {[
                        {
                            name: 'Name',
                            selector: (row) => row.name,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: 'Last Modified',
                            selector: (row) => row.lastModified,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: 'Size (MB)',
                            selector: (row) => Math.round(row.size / 1048576),
                            sortable: true,
                            center: true,
                        }
                    ]}
                /> }
            </CardBody>
            </Card>
        </Container></Row>
    )
}

export default FilesInstallers;