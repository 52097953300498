import React, { createContext, useState } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from './UserPool'
import { ToastBuilder, ApiRequestBuilder } from '../Builders';

const AccountContext = createContext();

const Account = (props) => {

  var [ userSession, setUserSession ] = useState({})

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject()
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results = {}

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute
                    results[Name] = Value
                  }

                  resolve(results)
                }
              })
            })

            const token = session.getIdToken().getJwtToken()

            /**
             * Now that we have an API token, let's make a call to API Gateway to get
             * more information about this user's environment.
             */
            localStorage.setItem('apiToken', token)

            /**
             * Set default color schemes
             */
            localStorage.setItem('default_color', '#26695c')
            localStorage.setItem('secondary_color', '#c29873')
            
            document.getElementById("favicon").href = "https://stackset-organization-master-35b0bd69-codebucket-1iv6lz12td8v0.s3.amazonaws.com/worldox-favicon.png"

            /**
             * ApiRequestBuilder('partnersPartnerIdGet', { partnerId : attributes['custom:partnerId'] }).then(function(results) { 
                 localStorage.setItem('default_color', results.data[0].default_color)
                 localStorage.setItem('secondary_color', results.data[0].secondary_color)
                 document.getElementById("favicon").href = results.data[0].favicon_url
                attributes.partner_logo = results.data[0].logo_url
             })
             */
            /**
             * Here we determine what level of access the current user has
             */
            attributes.access_level = attributes['custom:partnerId'] === '0' ? 'global' : attributes['custom:clientId'] === '0' ? 'partner' : 'client'
            attributes.portal_emulation_active = attributes['custom:emulationActive']

            /**
             * Until we determine the best way to access the session context while
             * creating the menu items, we need to keep things consistent and just
             * pass this value into localstorage
             */
            localStorage.setItem('accessLevel', attributes.access_level)

            setUserSession(attributes)
            
            resolve({
              user,
              headers: {
                Authorization: token,
              },
              ...session,
              ...attributes,
            })
          }
        })
      } else {
        reject()
      }
    })

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data)
        },

        onFailure: (err) => {
          switch (err.name) {
              case 'UserNotConfirmedException':
                  console.log(err)
                  ToastBuilder('info', 'Please Check Your Email to Verify Your Account')
              break;
              case 'NotAuthorizedException':
                  console.log(err)
                  ToastBuilder('error', 'Incorrect username or password.')
              break;
              default:
                  console.log(err)
                  ToastBuilder('info', 'Unknown Error on Log In')
              break;
          }
          reject(err)
        },

        newPasswordRequired: (data) => {
          ToastBuilder('warn', 'You Need to Reset Your Password')
          resolve(data)
        },
      })
    })

  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
    }
  }

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        userSession,
        logout,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}

export { Account, AccountContext }