import { Home, Users, Clipboard, FilePlus, DollarSign, Briefcase, Calendar, FileText } from 'react-feather';

export const buildMenuItems = () => {

    let Items = [ 
        {
            title: 'Calendar', icon: Calendar, type: 'link', active: false,  path: `${process.env.PUBLIC_URL}/calendar`, access: { global_access: 0, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Partners', icon: Briefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/partners`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Users', icon: Users, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/users`, type: 'link', title: 'List Users', access: { global_access: 1, partner_access: 1, client_access: 0 }  },
            ], access: { global_access: 1, partner_access: 1, client_access: 0 }
        },
        {
            title: 'Clients', icon: Clipboard, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/clients`, title: 'List Clients', type: 'link', access: { global_access: 0, partner_access: 1, client_access: 0 } },
            ], access: { global_access: 1, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Files', icon: FilePlus, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/files/installers`, title: 'View Installers', type: 'link', access: { global_access: 0, partner_access: 1, client_access: 0 } },
                { path: `${process.env.PUBLIC_URL}/files/wear`, title: 'View Wear Files', type: 'link', access: { global_access: 0, partner_access: 1, client_access: 0 } },
            ], access: { global_access: 1, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Infrastructure', icon: FileText, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/infrastructure`, access: { global_access: 0, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Finance', icon: DollarSign, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/finance`, access: { global_access: 1, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Requests', icon: Clipboard, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/requests`, title: 'List Requests', type: 'link', access: { global_access: 1, partner_access: 1, client_access: 0 } },
            ], access: { global_access: 1, partner_access: 1, client_access: 0 } 
        }
    ]

    /**
     * Sort items alphabetically before adding the top
     * level items
     */
    Items.sort((a,b) =>  a.title > b.title ? 1 : -1 )

    /**
     * For any menu items we want on top, we'll put them
     * at the start of the items array
     */
    Items.unshift(
        {
            title: 'Dashboard', icon: Home, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`, access: { global_access: 1, partner_access: 1, client_access: 1 }
        }
    )

    return [
        {
            menutitle: '',
            menucontent: 'Dashboards,Widgets',
            Items: Items
        }
    ]
}