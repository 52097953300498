import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Card, CardBody, Form } from 'reactstrap'
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ModalButtonBuilder, ModalLinkBuilder, FormOptionsBuilder } from '../../../../GlobalComponents/Builders';
import { resetAllFormFields, convertTimestampToTimePicker, buildOptionsFromArray } from '../../../../GlobalComponents/Helpers';

const ClientAccount = ({clientData}) => {
    
    let { clientId } = useParams();
  

    const [agreements, setAgreements] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);

    const { register, handleSubmit, resetField, setValue, formState: { errors } } = useForm();

    const getClientAgreements = () => {
        ApiRequestBuilder('clientsClientIdAgreementsGet', { clientId }, null, { setIsProcessing }).then(function(results) {
            setAgreements(results.data && results.data.length > 0 ? results.data : [])
        })
    }

    const handleAgreementViewEdit = (agreementView) => {
        
        setValue('editLicenseCount', agreementView.quantity)
        setValue('editStartDate', agreementView.start_date ? convertTimestampToTimePicker(agreementView.start_date) : null)
        setValue('editEndDate', agreementView.end_date ? convertTimestampToTimePicker(agreementView.end_date) : null)
    }

    const handleAgreementCreate = (formData) => {
        
        formData = {
            clientId : clientId,
            quantity: formData.createLicenseCount,
            startDate: formData.createStartDate,
            waiveInstallFee: formData.createWaiveInstallFee
        }

        ApiRequestBuilder('clientsClientIdAgreementsPost', { clientId }, formData).then(function(results) {

            ToastBuilder('success', 'Agreement Created Successfully')
            resetAllFormFields(formData, resetField)
            setTimeout(() => getClientAgreements(), 3000)
        })
    }

    const handleAgreementUpdate = (formData, agreementId, additionId) => {
        
        formData = {
            clientId: clientId,
            quantity: formData.editLicenseCount,
            additionId: additionId,
            endDate: formData.editEndDate
        }

       ApiRequestBuilder('clientsClientIdAgreementsAgreementIdPatch', { clientId, agreementId }, formData).then(function(results) {

            ToastBuilder('success', 'Agreement Created Successfully')
            resetAllFormFields(formData, resetField)
            setTimeout(() => getClientAgreements(), 3000)
        })
    }

    useEffect(() => {
        getClientAgreements()

        if (clientData)
        {                
            setValue('createLicenseCount', clientData.license_count)
            setValue('createStartDate', clientData.installation_date ? convertTimestampToTimePicker(moment(clientData.installation_date).unix()) : null)
            setValue('createWaiveInstallFee', clientData.waive_install_fee)
        }
    }, []);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <TableBuilder
                        data={agreements}
                        processing={isProcessing}
                        columns = {[
                            {
                                name: 'Agreement',
                                selector: (row) => row.name,
                                sortable: true,
                                center: true,
                                wrap: true
                            },
                            {
                                name: 'Billing Amount',
                                selector: (row) => '$' + row.quantity * row.amount,
                                sortable: true,
                                center: true,
                                wrap: true
                            },
                            {
                                name: 'Quantity',
                                selector: (row) => row.quantity,
                                sortable: true,
                                center: true,
                                wrap: true
                            },
                            {
                                name: 'Start Date',
                                selector: (row) => row.start_date ? moment.utc(row.start_date * 1000).format('l') : '-',
                                sortable: true,
                                center: true,
                                wrap: true
                            },
                            {
                                name: 'End Date',
                                selector: (row) => row.end_date ? moment.utc(row.end_date * 1000).format('l') : '-',
                                sortable: true,
                                center: true,
                                wrap: true
                            },
                            {
                                name: 'Status',
                                selector: (row) => row.status,
                                sortable: true,
                                center: true,
                                wrap: true
                            },
                            {
                                name: 'Manage', 
                                selector: (row) => <ModalLinkBuilder label='View / Edit' title='Edit Agreement'
                                    onClick={() => handleAgreementViewEdit(row)}
                                    body={
                                        <Form id='edit-agreement-form' onSubmit={handleSubmit((data) => handleAgreementUpdate(data, row.agreement_id, row.addition_id))}>
                                            <RegisteredInputsBuilder 
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={[
                                                    {
                                                        label: 'License Count',
                                                        inputSize: 4,
                                                        size: 12,
                                                        type: 'number',
                                                        name: 'editLicenseCount'
                                                    },
                                                    {
                                                        label: 'Start Date',
                                                        size: 12,
                                                        type: 'date',
                                                        name: 'editStartDate'
                                                    },
                                                    {
                                                        label: 'Cancel Date',
                                                        size: 12,
                                                        type: 'date',
                                                        name: 'editEndDate'
                                                    },
                                                ]}
                                            /> 
                                        </Form>
                                    }
                                />,
                                center: true,
                            }
                        ]}
                        actions={
                            <Fragment>
                                <ModalButtonBuilder title='Create Agreement' className='btn-primary'
                                    body={
                                        <Form id='create-agreement-form' onSubmit={handleSubmit(handleAgreementCreate)}>
                                            <RegisteredInputsBuilder 
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={[
                                                    {
                                                        label: 'License Count',
                                                        inputSize: 4,
                                                        size: 12,
                                                        type: 'number',
                                                        name: 'createLicenseCount'
                                                    },
                                                    {
                                                        label: 'Start Date',
                                                        size: 12,
                                                        type: 'date',
                                                        name: 'createStartDate'
                                                    },
                                                    {
                                                        label: 'Waive Install Fee',
                                                        type: 'select',
                                                        size: 12,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['Client WILL be Charged a Fee', 'Client Will NOT be Charged a Fee'])}/>,
                                                        name: 'createWaiveInstallFee'
                                                    },
                                                ]}
                                            /> 
                                        </Form>
                                    }
                                />
                            </Fragment>
                        }/>
                </CardBody>
            </Card>
        </Fragment>
    );
};
export default ClientAccount;