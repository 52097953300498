import { Container, Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import React, { Fragment } from 'react';
import { Breadcrumbs, H5, H4, P } from '../../../AbstractElements';
import { Database, ShoppingBag} from 'react-feather';
import CountUp from 'react-countup';

const quickStats = [
    {
        id: 1,
        classCompo: 'bg-primary',
        icon: <Database />,
        title: 'Earnings',
        count: <CountUp end={195} duration={3} />,
        iconWithClass: <Database className="icon-bg" />
    },
    {
        id: 2,
        classCompo: 'bg-secondary',
        icon: <ShoppingBag />,
        title: 'Active Clients',
        count: <CountUp end={1} duration={1} />,
        iconWithClass: <ShoppingBag className="icon-bg" />
    }
];

const Dashboard = () => {
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Fragment>
                        {quickStats.map((item) =>
                            <Col sm="6" xl="3" lg="6" key={item.id}>
                                <Card className="o-hidden border-0">
                                    <CardBody className={item.classCompo}>
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center">
                                                {item.icon}
                                            </div>
                                            <div className="media-body">
                                                <span className="m-0">{item.title}</span>
                                                <H4 attrH4={{ className: 'mb-0 counter' }} >{item.count}</H4>
                                                {item.iconWithClass}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Fragment>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Dashboard;