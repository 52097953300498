import React, { Fragment, useContext, useEffect, useState } from 'react';
import { LI } from '../../../AbstractElements';
import { AccountContext } from '../../../GlobalComponents/Authentication/Accounts';
import { DropdownBuilder } from '../../../GlobalComponents/Builders';

const UserOptions = () => {
    
    const { logout, userSession } = useContext(AccountContext); 

    const logUserOut = () => {
        logout()
        window.location.href = process.env.PUBLIC_URL + '/login'
    }

    return (
        <Fragment>
            <LI attrLI={{ className: 'onhover-dropdown p-0' }} >
                <DropdownBuilder 
                    label={<span style={{fontSize: 18, color:(localStorage.getItem('mix_background_layout') === 'dark-only' ? 'white' : 'black')}}>{userSession.name}</span>}
                    items={[
                        {
                            label : 'Log Out',
                            onClick: logUserOut
                        }
                    ]}
                />
            </LI>
        </Fragment>
    );
};

export default UserOptions;