import { Navigate } from 'react-router-dom';

import Dashboard from '../Components/Dashboard';
import Clients from '../Components/Clients';
import Client from '../Components/Clients/Client';
import Calendar from '../Components/Calendar';
import Files from '../Components/Files';
import FilesInstallers from '../Components/Files/Installers';
import Infrastructure from '../Components/Infrastructure';
import User from '../Components/Users/User';
import UsersList from '../Components/Users/List';
import { Logout } from '../GlobalComponents/Authentication'; 
import FilesWear from '../Components/Files/Wear';
import Finance from '../Components/Finance';
import Requests from '../Components/Requests';
import Request from '../Components/Requests/Request';
import PartnerList from '../Components/Partners';

export const routes = [
        
        { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard />, access: {global_access: 1, partner_access: 1, client_access: 1} },

        { path: `${process.env.PUBLIC_URL}/clients`, Component: <Clients />, access: {global_access: 0, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/clients/:clientId`, Component: <Client />, access: {global_access: 0, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/calendar`, Component: <Calendar />, access: {global_access: 0, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/partners`, Component: <PartnerList />, access: {global_access: 1, partner_access: 0, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/files`, Component: <Files />, access: {global_access: 0, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/files/installers`, Component: <FilesInstallers />, access: {global_access: 0, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/files/wear`, Component: <FilesWear />, access: {global_access: 0, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/infrastructure`, Component: <Infrastructure />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/finance`, Component: <Finance />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/requests`, Component: <Requests />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/requests/:requestId`, Component: <Request />, access: {global_access: 0, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/users`, Component: <UsersList />, access: {global_access: 1, partner_access: 1, client_access: 1 }},
        { path: `${process.env.PUBLIC_URL}/users/:userId`, Component: <User />, access: {global_access: 1, partner_access: 1, client_access: 1 }},

        { path: `${process.env.PUBLIC_URL}/logout`, Component: <Logout />, access: {global_access: 1, partner_access: 1, client_access: 1 }},

        // Safety redirect for unknown url paths
        { path: `${process.env.PUBLIC_URL}/*`, Component: <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />, access: {global_access: 1, partner_access: 1, client_access: 1 }}
];