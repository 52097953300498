
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';
import React, { useContext } from 'react';
import ClientDashboard from './Client';
import PartnerDashboard from './Partner';
import GlobalDashboard from './Global';

const Dashboard = ({}) => {

    document.title = 'Dashboard'

    const { userSession } = useContext(AccountContext);

    switch (userSession.access_level) {
        case 'global':
            return <GlobalDashboard/>
        case 'partner':
            return <PartnerDashboard/>
        default:
            return <ClientDashboard/>
    }
}

export default Dashboard