import { Container, Row, Col, Dropdown, Form, CardHeader, Card,  CardBody, CardFooter, Spinner } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import TableBuilder, { ApiRequestBuilder } from '../../../GlobalComponents/Builders';

const FilesWear = () => {

    const [isProcessing, setIsProcessing] = useState(true);
    const [wearData, setWearData] = useState([]);

    const getWearFiles = () => {
    
        ApiRequestBuilder('filesWearGet', null, null, { setIsProcessing })
            .then(function(results) {
                setWearData(results.data && results.data.length > 0 ? results.data : [])
            })
            .catch(err => console.log('err', err))
    }

    useEffect(() => {
        
        document.title = 'View Wear  Files'
        
        getWearFiles()
    }, []);

    return (
        <Row>
            <Container>
                <Card>
                    <CardBody>
                        <TableBuilder
                            data={wearData}
                            title='List Wears'
                            processing={isProcessing}
                            columns = {[
                                {
                                    name: 'Client ID',
                                    selector: (row) => row.worldox_client_id,
                                    sortable: true,
                                    center: true,
                                },
                                {
                                    name: 'Client',
                                    selector: (row) => row.clientName,
                                    sortable: true,
                                    center: true,
                                },
                                {
                                    name: 'File',
                                    selector: (row) => row.file,
                                    sortable: true,
                                    center: true,
                                },
                                {
                                    name: 'Last Modified',
                                    selector: (row) => row.lastModified,
                                    sortable: true,
                                    center: true,
                                },
                                {
                                    name: '',
                                    selector: (row) => row ? <a href={row.url}>Download</a> : '-',
                                    sortable: true,
                                    center: true,
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </Container>
        </Row>
    )
}

export default FilesWear;