import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody } from 'reactstrap'

const FinanceList = () => {

    const [clientData, setClientData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
    
        document.title = 'Upcoming Invoice'

        ApiRequestBuilder('financeGet', null, null, { setIsProcessing }).then(function(results) {
            setClientData(results.data && results.data.length > 0 ? results.data : [])
        })

    }, []);

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['id', 'name']}
                                data={clientData}
                                pagination={false}
                                processing={isProcessing}
                                title='Clients on Next Invoice'
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.worldox_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'License Count',
                                        selector: (row) => row.license_count,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Installation Fee',
                                        selector: (row) => row.installation_fee? row.installation_fee : '0',
                                        sortable: true,
                                        center: true,
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    );
};

export default FinanceList;